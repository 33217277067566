export default [
	{
		path: '/nutriActivity',
		name: 'nutriActivity',
		component: () => import(/* nutriActivity*/ './index.vue'),
		meta: {
			title: '拾青学堂',
			// keepAlive: true  //是否设置为缓存数据页面
		},
		children: [
			{
				path: 'video/index',
				name: 'nutriActivity_video',
				component: () => import(/* nutriActivity_video*/ './pages/video/index.vue'),
				meta: {
					title: '拾青学堂',
					auth: true,
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'mp/index',
				name: 'nutriActivity_index',
				component: () => import(/* nutriActivity_index*/ './pages/mp/index.vue'),
				meta: {
					title: '拾青学堂',
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'mp/indexMiddle',
				name: 'nutriActivity_indexMiddle',
				component: () => import(/* nutriActivity_indexMiddle*/ './pages/mp/indexMiddle.vue'),
				meta: {
					title: '拾青学堂',
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'mp/indexPhone',
				name: 'nutriActivity_mp_indexPhone',
				component: () => import(/* nutriActivity_mp_indexPhone*/ './pages/mp/indexPhone.vue'),
				meta: {
					title: '拾青学堂',
					auth: false, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'mp/indexQuestion',
				name: 'nutriActivity_mp_indexQuestion',
				component: () => import(/* nutriActivity_mp_indexQuestion*/ './pages/mp/indexQuestion.vue'),
				meta: {
					title: '拾青学堂',
					auth: false, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/index',
				name: 'nutriActivity_web_index',
				component: () => import(/* nutriActivity_web_index*/ './pages/web/index.vue'),
				meta: {
					title: '拾青学堂',
					auth: false, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/indexMiddle',
				name: 'nutriActivity_web_indexMiddle',
				component: () => import(/* nutriActivity_indexMiddle*/ './pages/web/indexMiddle.vue'),
				meta: {
					title: '拾青学堂',
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/indexPhone',
				name: 'nutriActivity_web_indexPhone',
				component: () => import(/* nutriActivity_web_index*/ './pages/web/indexPhone.vue'),
				meta: {
					title: '拾青学堂',
					auth: false, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/step1',
				name: 'nutriActivity_web_step1',
				component: () => import(/* nutriActivity_web_step1*/ './pages/web/type0/step1.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/step2',
				name: 'nutriActivity_web_step2',
				component: () => import(/* nutriActivity_web_step2*/ './pages/web/type0/step2.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/type0/step1',
				name: 'nutriActivity_web_type0_step1',
				component: () => import(/* nutriActivity_web_type0_step1*/ './pages/web/type0/step1.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/type0/step2',
				name: 'nutriActivity_web_type0_step2',
				component: () => import(/* nutriActivity_web_type0_step2*/ './pages/web/type0/step2.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/type1/step1',
				name: 'nutriActivity_web_type1_step1',
				component: () => import(/* nutriActivity_web_type1_step1*/ './pages/web/type1/step1.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/type1/step2',
				name: 'nutriActivity_web_type1_step2',
				component: () => import(/* nutriActivity_web_type1_step2*/ './pages/web/type1/step2.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/type2/step1',
				name: 'nutriActivity_web_type2_step1',
				component: () => import(/* nutriActivity_web_type2_step1*/ './pages/web/type2/step1.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/type2/step2',
				name: 'nutriActivity_web_type2_step2',
				component: () => import(/* nutriActivity_web_type2_step2*/ './pages/web/type2/step2.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					checkSnapshotuser: true, // 微信授权进入快照页面，需要提示小手
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'customerService/index',
				name: 'customerService_index',
				component: () => import(/* customerService_index*/ './pages/customerService/index.vue'),
				meta: {
					title: '拾青学堂',
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'customerService/type1/step1',
				name: 'nutriActivity_customerService_type1_step1',
				component: () => import(/* nutriActivity_customerService_type1_step1*/ './pages/customerService/type1/step1.vue'),
				meta: {
					title: '拾青学堂',
					// auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'customerService/type1/step2',
				name: 'nutriActivity_customerService_type1_step2',
				component: () => import(/* nutriActivity_customerService_type1_step2*/ './pages/customerService/type1/step2.vue'),
				meta: {
					title: '拾青学堂',
					// auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'web/success',
				name: 'nutriActivity_web_success',
				component: () => import(/* nutriActivity_web_success*/ './pages/web/success.vue'),
				meta: {
					title: '拾青学堂',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'live/index',
				name: 'live_index',
				component: () => import('./pages/live/index.vue'),
				meta: {
					title: '拾青学堂',
					// auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'live/advancedCourses',
				name: 'live_advancedCourses',
				component: () => import('./pages/live/advancedCourses.vue'),
				meta: {
					title: '拾青学堂',
					// auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'middlePage/index',
				name: 'active_middlePage',
				component: () => import('./pages/middlePage/index.vue'),
				meta: {
					title: '拾青学堂',
					auth: false, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
		],
	},
];
