export default [
	{
		path: '/payActivity',
		name: 'payActivity',
		component: () => import(/* payActivity*/ './index.vue'),
		meta: {
			title: '拾青学堂',
			// keepAlive: true  //是否设置为缓存数据页面
		},
		children: [
			{
				path: 'indexPhone',
				name: 'payActivity_web_indexPhone',
				component: () => import(/* payActivity_web_indexPhone*/ './pages/web/indexPhone.vue'),
				meta: {
					title: '拾青学堂'
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'signUp',
				name: 'payActivity_web_signUp',
				component: () => import(/* payActivity_web_signUp*/ './pages/web/signUp.vue'),
				meta: {
					title: '拾青学堂'
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'blackList',
				name: 'payActivity_web_blackList',
				component: () => import(/* payActivity_web_blackList*/ './pages/web/blackList.vue'),
				meta: {
					title: '拾青学堂'
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'entered',
				name: 'payActivity_web_entered',
				component: () => import(/* payActivity_web_entered*/ './pages/web/entered.vue'),
				meta: {
					title: '拾青学堂'
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'groupCode',
				name: 'payActivity_web_groupCode',
				component: () => import(/* payActivity_web_groupCode*/ './pages/web/groupCode.vue'),
				meta: {
					title: '拾青学堂'
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'teacherQr',
				name: 'payActivity_web_teacherQr',
				component: () => import(/* payActivity_web_teacherQr*/ './pages/web/teacherQr.vue'),
				meta: {
					title: '拾青学堂'
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'question',
				name: 'payActivity_web_question',
				component: () => import(/* payActivity_web_question*/ './pages/web/question.vue'),
				meta: {
					title: '健康诊断与评估问卷'
					// keepAlive: true  //是否设置为缓存数据页面
				},
			}
		],
	},
];
