export default [
	{
		path: '/course',
		name: 'Course',
		component: () => import(/* Course*/ './index.vue'),
		meta: {
			title: '课程中心',
			auth: true, // 如果此路由需要微信授权请设置为true,默认为false
			// keepAlive: true  //是否设置为缓存数据页面
		},
		children: [
			{
				path: 'index',
				name: 'CourseIndex',
				component: () => import(/* CourseIndex*/ './pages/index.vue'),
				meta: {
					title: '学习中心',
					showTab: true,
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'home',
				name: 'CourseHome',
				component: () => import(/* CourseIndex*/ './pages/home.vue'),
				meta: {
					title: '首页',
					showTab: true,
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'list',
				name: 'CourseList',
				component: () => import(/* CourseIndex*/ './pages/list.vue'),
				meta: {
					title: '课程中心',
					showTab: true,
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'detail',
				name: 'CourseDetail',
				component: () => import(/* CourseDetail*/ './pages/CourseDetail.vue'),
				meta: {
					title: '课程详情',
					showTab: true,
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'about',
				name: 'CourseAbout',
				component: () => import(/* CourseAbout*/ './pages/About.vue'),
				meta: {
					title: '关于我们',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'my',
				name: 'CourseMy',
				component: () => import(/* CourseMy*/ './pages/My.vue'),
				meta: {
					title: '个人中心',
					showTab: true,
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'teacherList',
				name: 'CourseTeacherList',
				component: () => import(/* CourseMy*/ './pages/teacherList.vue'),
				meta: {
					title: '个人中心',
					showTab: true,
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'teacherQr',
				name: 'CourseTeacherQr',
				component: () => import(/* CourseMy*/ './pages/teacherQr.vue'),
				meta: {
					title: '个人中心',
					// showTab: true,
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
			{
				path: 'video',
				name: 'CourseVideo',
				component: () => import(/* CourseVideo*/ './pages/Video.vue'),
				meta: {
					title: '学习中心',
					auth: true, // 如果此路由需要微信授权请设置为true,默认为false
					// keepAlive: true  //是否设置为缓存数据页面
				},
			},
		],
	},
];
