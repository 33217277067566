<template>
  <div id="app">
    <keep-alive v-if="$route.meta.keepAlive">
      <router-view />
    </keep-alive>
    <router-view v-else />
  </div>
</template>

<script>
import './styles/index.scss'
import Cookies from 'js-cookie'
import store from '@/store'
import VConsole from 'vconsole'
import { isWeChat, historyReplaceTab } from '@/utils/index'

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  created() {
    // const token = Cookies.get('h5token')
    // if (token) {
    //   store.commit('setToken', token)
    //   store.dispatch('getUserInfo')
    // }
    if (process.env.VUE_APP_MODE !== 'production') {
      new VConsole()
    }
  },
  mounted() {
    historyReplaceTab({ name: 'code', value: '' })
  }
}
</script>

<style lang="scss">
body,
html {
  height: 100%;
}

#app {
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
