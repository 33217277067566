import Vue from 'vue'
// import 'intersection-observer'
import doTrack from '@/utils/pointLog'

Vue.directive('track', {
  bind: function(el, binding) {
    el.onclick = function() {
      doTrack({ type: 'click', ...binding.value })
    }
  },
  inserted: function(el, binding) {
  },
  update: function(el, binding) {
  },
  componentUpdated: function(el, binding) {},
  unbind: function(el, binding) {}
})

// 曝光监听
// var _observer = null
// 曝光指令
// Vue.directive('exposureTrack', {
//   bind: function(el, binding) {
//     el.params = binding
//     if (!_observer) {
//       _observer = new IntersectionObserver((entries) => {
//         entries.forEach((entry) => {
//           if (entry.isIntersecting) {
//             kmfSensorsTrack(entry.target.params)
//           }
//         })
//       })
//     }
//     _observer.observe(el)
//   },
//   unbind: function(el) {
//     _observer && _observer.unobserve(el)
//   }
// })
