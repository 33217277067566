export default [
	{
		path: '/enroll',
		name: 'enroll',
		component: () => import(/* enroll*/ './index.vue'),
		meta: {
			title: '拾青学堂',
			// keepAlive: true  //是否设置为缓存数据页面
		},
		children: [
			{
				path: 'signstatus',
				name: 'enrollCourses',
				component: () => import(/* enrollCourses*/ './pages/enrollCourses.vue'),
				meta: {
					title: '拾青学堂',
					auth: true,
					// keepAlive: true  //是否设置为缓存数据页面
				},
			}
		],
	},
];
