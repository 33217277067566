export const isWeChat = () => {
	var ua = window.navigator.userAgent.toLowerCase();
	// eslint-disable-next-line eqeqeq
	return ua.match(/MicroMessenger/i) == 'micromessenger';
};

export const getDeviceInfo = () => {
	// 获取设备用户代理字符串
	const userAgent = navigator.userAgent;
	const devcieInfo = {
		model: '',
		version: '',
	};
	if (userAgent.indexOf('iPhone') > -1) {
		// 判断用户代理字符串中是否包含'iPhone'，若包含则表示是iPhone手机
		// 解析出iPhone的型号和版本信息
		const regex = /iPhone\s(OS\s)?(\d[\d_]+)+/g;
		const matches = regex.exec(userAgent);
		if (matches) {
			const model = 'iPhone';
			const version = matches[2].replace(/_/g, '.');
			// console.log('Device model: ' + model)
			// console.log('iOS version: ' + version)
			devcieInfo.model = model;
			devcieInfo.version = version;
		}
	} else if (userAgent.indexOf('Android') > -1) {
		// 判断用户代理字符串中是否包含'Android'，若包含则表示是Android手机
		// 解析出Android的型号和版本信息
		const regex = /Android\s(\d[\d.]+)+;/g;
		const matches = regex.exec(userAgent);
		if (matches) {
			const version = matches[1];
			// 判断设备型号
			const model = 'android';
			// if (userAgent.indexOf('Mi') > -1) {
			//   model = 'Xiaomi'
			// } else if (userAgent.indexOf('HUAWEI') > -1) {
			//   model = 'HUAWEI'
			// } else if (userAgent.indexOf('OPPO') > -1) {
			//   model = 'OPPO'
			// } else if (userAgent.indexOf('vivo') > -1) {
			//   model = 'vivo'
			// } else {
			//   model = 'Other android'
			// }
			// console.log('Device model: ' + model)
			// console.log('Android version: ' + version)
			devcieInfo.model = model;
			devcieInfo.version = version;
		}
	}
	return devcieInfo;
};

export const getQueryParams = name => {
	var url_string = location.href;
	var params = url_string.split('?')[1]; // 获取?号后面的参数 name=mick&age=20
	if (!params) return null; // 如果无参，返回null
	var vars = params.split('&'); // 将参数分割成数组 ['name=mick', 'age=20']
	var query_string = {};
	for (var i = 0; i < vars.length; i++) {
		var pair = vars[i].split('='); // 将参数的key和值进行分割成 ['name', 'mick']
		var key = decodeURIComponent(pair[0]); // 参数key
		var value = decodeURIComponent(pair[1]); // 参数值
		query_string[key] = value;
	}
	return query_string;
};

// 新增或者替换某个query参数
export const historyReplaceTab = ({ name, value }) => {
	const stateObj = history.state || {};
	const queryObj = {};
	let url = location.origin + location.pathname;
	if (location.hash) {
		url += location.hash.split('?')[0];
	}
	const arr = location.href.split('?');
	// 获取url自带的参数
	if (arr[1]) {
		const aKeyValue = arr[1].split('&');
		aKeyValue.forEach(ele => {
			const oKeyValue = ele.split('=');
			if (oKeyValue[1] && oKeyValue[1] !== 'undefined') {
				stateObj[oKeyValue[0]] = oKeyValue[1];
				queryObj[oKeyValue[0]] = oKeyValue[1];
			}
		});
	}
	queryObj[name] = value;
	let query = '';
	Object.keys(queryObj).forEach(key => {
		if (queryObj[key]) {
			// 抛弃空值
			if (query) {
				query += `&${key}=${queryObj[key]}`;
			} else {
				query += `?${key}=${queryObj[key]}`;
			}
		}
	});

	history.replaceState(stateObj, history.title, url + query);
};

export const createId = () => {
	return new Date().getTime() + Math.random().toString(36).substring(2);
};

// 获取URL是字符串的param值
export const getParamValue = (url, paramName) => {
	const match = url.match(new RegExp(`${paramName}=([^&]+)`));
	return match ? match[1] : null;
}

// 获取URL是字符串的param值，并返回一个对象
export const getUrlParamsObject = (url) => {
	const queryString = url.split('?')[1];
	if (!queryString) {
		return {};
	}
	const paramsArray = queryString.split('&');
	const paramsObject = {};
	for (const param of paramsArray) {
		const [key, value] = param.split('=');
		paramsObject[key] = decodeURIComponent(value);
	}
	return paramsObject;
}

export const getRandomFromArray = (arr, count) => {
	const result = [];
	const length = arr.length;
	// 如果需要获取的数量大于数组长度，则直接返回数组本身
	if (count >= length) {
		return arr;
	}
	// 循环获取指定数量的随机值
	while (result.length < count) {
		// 生成一个随机索引
		const randomIndex = Math.floor(Math.random() * length);
		// 检查该值是否已经在结果数组中，如果不在，则添加到结果数组中
		if (!result.includes(arr[randomIndex])) {
			result.push(arr[randomIndex]);
		}
	}
	return result;
}
