import request from '@/utils/request';

export const getLoingInfo = data => {
	return request({
		url: '/console/album/v1',
		method: 'post',
		type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		data,
	});
};

export const postTrack = data => {
	return request({
		url: '/promotion/statistics/data',
		method: 'post',
		type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		data,
	});
};

export const postTrackCourse = data => {
	return request({
		url: '/business/userCourseStatistics/log',
		method: 'post',
		type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		data,
	});
};

export const postGetWechatConfig = data => {
	return request({
		url: '/open-api/wechat/service/serviceJsTicketSign',
		method: 'post',
		type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		data,
	});
};

// 发送短信验证码
export const postSendPhoneCode = data => {
	return request({
		url: '/open-api/sms/sendAuthCode',
		method: 'post',
		type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		data,
	});
};

// 校验短信验证码
export const postValidPhoneCode = data => {
	return request({
		url: '/open-api/sms/authCode',
		method: 'post',
		type: 'json', // formDate类型数据值为 'form'
		// type: 'form',
		data,
	});
};
