import Vue from 'vue';
import { Lazyload } from 'vant';
import App from './App.vue';
import router from './router';
import store from './store';
import './directive';
import 'amfe-flexible'; // 移动端适配
import './assets/styles/reset.scss';
import './assets/styles/border.scss';
import '@/utils/wechat';
// 在移动端H5开发过程中，关于点触可能会遇到手动点击与真正触发click事件会存在300ms的延迟，因此需要引入fastclick的库。
import fastClick from 'fastclick';
fastClick.attach(document.body);

Vue.config.productionTip = false;
Vue.use(Lazyload, {
	preLoad: 1.5
});

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
