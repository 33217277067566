import doTrack from './pointLog';

export const checksPagseView = path => {
	const str = sessionStorage.getItem('cache_track');
	const arr = str ? JSON.parse(str) : [];
	if (!arr.includes(path)) arr.push(path);
	sessionStorage.setItem('cache_track', JSON.stringify(arr));
};

export const cacheTrackGlobal = {
	set(data) {
		const str = localStorage.getItem('need_point_cache-course');
		const arr = str ? JSON.parse(str) : [];
		const index = arr.findIndex(x => data.keyId === x.keyId);
		if (index > -1) {
			arr[index] = Object.assign(arr[index], data);
		} else {
			arr.push(data);
		}
		localStorage.setItem('need_point_cache-course', JSON.stringify([...arr]));
	},
	get() {
		const str = localStorage.getItem('need_point_cache-course');
		const arr = str ? JSON.parse(str) : [];
		return arr;
	},
	done() {
		cacheTrackGlobal.get().forEach(element => {
			doTrack(element);
		});
		localStorage.removeItem('need_point_cache-course');
	},
};
