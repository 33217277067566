import axios from 'axios';
import Qs from 'qs';
import store from '@/store/index';
import Cookies from 'js-cookie';

const service = axios.create({
	// 请求地主 dev-> /api 每一次请求携带
	baseURL: process.env.VUE_APP_BASE_API,
	// 超时时间定义
	timeout: 1000 * 15,
	// 响应数据类型
	// responseType: 'json',
	// 默认请求头设置
	headers: {
		'Access-Control-Allow-Origin': '*',
		// 'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
	},
	// 超时时间定义
	transformRequest: [
		function (data) {
			return data;
		},
	],
	// 响应后数据处理
	transformResponse: [
		function (data) {
			return data;
		},
	],
	// 当我们把此配置项设置成默认配置项并且设置成true的时候，axios就可以设置cookies了。
	withCredentials: false,
});

service.interceptors.request.use(
	config => {
		// if (isLoading(config)) {
		//   loadingInstance = Loading.service({ fullscreen: true, text: '加载中...' })
		// }
		config.headers['Authorization'] = Cookies.get('h5token');
		return config;
	},
	error => {
		// if (loadingInstance) {
		//   loadingInstance.close()
		// }
		return Promise.reject(error);
	}
);
// response interceptor
service.interceptors.response.use(
	response => {
		return response;
	},
	err => {
		return Promise.reject(err);
	}
);

export default ({ url = '/', method = 'get', data = {}, params = {}, type = 'form', config = {} }) => {
	const options = {
		url,
		method,
	};
	// 设置请求头
	const ContentTypes = key => {
		const types = {
			// 表单数据
			form: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
			// json 数据
			json: { 'Content-Type': 'application/json;charset=UTF-8' },
			// 文件上传
			file: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
		};
		return types[key];
	};
	// 设置参数
	const setParams = (key, data) => {
		Object.keys(data).forEach(key => {
			if (data[key] === '') {
				data[key] = null;
			}
		});
		const funs = {
			form: data => Qs.stringify(data),
			json: data => JSON.stringify(data),
			file: data => {
				// 文件数据上传
				const formData = new FormData();
				for (const n in data) {
					formData.append(n, data[n]);
				}
				return formData;
			},
		};
		return funs[key](data);
	};
	if (method === 'get') {
		options.params = params;
	} else if (method === 'post') {
		if (type === 'json') {
			options.headers = ContentTypes('json');
			options.data = setParams('json', data);
		} else if (type === 'file') {
			options.headers = ContentTypes('file');
			options.data = setParams('file', data);
		} else if (type === 'form') {
			options.headers = ContentTypes('form');
			options.data = setParams('form', data);
		}
	}
	return new Promise((resolve, reject) => {
		try {
			service(Object.assign(options, config))
				.then(resAll => {
					const res = resAll.data && JSON.parse(resAll.data);
					if (!res.code) {
						reject(res);
					} else {
						if (res.code === 200) {
							// 正常返回
							resolve(res.data);
						} else if (res.code === 401) {
							if (!Cookies.get('h5token')) {
								reject({ data: { msg: '没有cookie' } });
								return;
							}
							// 正常返回
							Cookies.remove('h5token');
							let domain = '.jkqingman.com';
							if (process.env.VUE_APP_MODE !== 'production') {
								domain = '.jkqianmei.com';
							}
							Cookies.remove('h5token', {
								domain: domain,
							});
							console.error(res);
							// 刷新后会重新登录
							setTimeout(() => {
								location.reload();
							}, 0);
							reject(res);
						} else {
							reject(res);
						}
					}
				})
				.catch(err => {
					reject(err);
				});
		} catch (error) {
			reject(error);
			console.error(error);
		}
	});
};
