import { postTrack, postTrackCourse } from '../api/common';
import { getDeviceInfo, createId } from './index';
import store from '@/store/index';

// 判断是否记录过pv
export const checkPageView = path => {
	// 缓存path，用户一次访问页面只会记录一次pv
	let result = '';
	const str = sessionStorage.getItem('cache_track_log');
	const arr = str ? JSON.parse(str) : [];
	if (arr.includes(path)) {
		result = true;
	} else {
		result = false;
		arr.push(path);
	}
	sessionStorage.setItem('cache_track_log', JSON.stringify(arr));
	return result;
};

export default data => {
	return new Promise((resolve, reject) => {
		const deviceInfo = getDeviceInfo();
		const userInfo = store.state.userInfo;
		const queryInfo = store.state.queryInfo;
		const mode = process.env.VUE_APP_MODE;
		// getQueryParams

		const doTrack = () => {
			const commonParams = {
				screenHeight: document.documentElement.clientHeight,
				screenWidth: document.documentElement.clientWidth,
				url: location.href,
				title: document.title,
				platform: 'h5',
				systemVersion: deviceInfo.version,
				promotionId: queryInfo.promotionId,
				linkNo: queryInfo.linkNo,
				unionId: userInfo.unionid,
				mobile: userInfo.phone,
				userId: userInfo.id,
				openid: userInfo.openid,
				deviceOs: deviceInfo.model + ': ' + deviceInfo.version,
				enviropoment: mode,
				pageType: '', // 1 计算落地页pv专用，
				type: '', // pageView,click,login
			};
			data = data || {};
			// 添加参数
			const uuid = localStorage.getItem('uuid');
			if (uuid) {
				data.uuid = uuid;
			} else {
				data.uuid = createId();
				localStorage.setItem('uuid', data.uuid);
			}
			// 将data上的公共参数赋值给commonParams，然后删除data上的公共参数
			Object.keys(commonParams).forEach(key => {
				if (data[key]) {
					commonParams[key] = data[key];
					delete data[key];
				}
			});
			// 埋点来源
			if (data.source === undefined) {
				// 0 微信客服
				data.source = queryInfo.source;
			}
			if (location.href.includes('/course/')) {
				postTrackCourse({
					...commonParams,
					dataJson: data,
				})
					.then(res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					});
			} else {
				postTrack({
					...commonParams,
					dataJson: data,
				})
					.then(res => {
						resolve(res);
					})
					.catch(err => {
						reject(err);
					});
			}
		};
		doTrack();
		// if (userInfo.unionid) {
		//   // 已登录
		//   doTrack()
		// } else {
		//   // 未登录
		//   doTrack()
		// }
	});
};
