import { isWeChat, historyReplaceTab } from '@/utils/index';
import { postGetWechatConfig } from '@/api/common';
import store from '@/store/index';

const shareUrl = process.env.VUE_APP_URL;

const wxSetting = () => {
	postGetWechatConfig({
		url: shareUrl,
		type: 1,
	}).then(res => {
		// jsapi_ticket: "O3SMpm8bG7kJnF36aXbe85Zk2a0oxB1EDnNyibOTYmc422twOtnxB186XU8AuBrbXk1yEpEvxJ2BxYm_rw2jtA"
		// noncestr: "f502ad5c664f4cea983c6a7af5dacb9c"
		// sign: "30c4b0a55f80607ab254478b196ed1f05581a1ee"
		// timestamp: "1694416113"
		// eslint-disable-next-line no-undef
		wx.config({
			debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
			appId: res.appId, // 必填，公众号的唯一标识
			timestamp: res.timestamp, // 必填，生成签名的时间戳
			nonceStr: res.nonceStr, // 必填，生成签名的随机串
			signature: res.sign, // 必填，签名
			jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareAppMessage', 'onMenuShareTimeline'], // 必填，需要使用的JS接口列表
		});

		// eslint-disable-next-line no-undef
		wx.ready(function () {
			// 需在用户可能点击分享按钮前就先调用
			// eslint-disable-next-line no-undef
			wx.onMenuShareAppMessage({
				title: '家庭健康养生班', // 分享标题
				desc: '学养生，保障自己和家庭的健康', // 分享描述
				type: 'link',
				link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://images.jkqingman.com/MP/wx_share.jpg', // 分享图标
				success: function () {
					// 设置成功
				},
				fail: function (res) {
					// alert(JSON.stringify(res));
				},
			});
			// eslint-disable-next-line no-undef
			wx.updateAppMessageShareData({
				title: '家庭健康养生班', // 分享标题
				desc: '学养生，保障自己和家庭的健康', // 分享描述
				link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://images.jkqingman.com/MP/wx_share.jpg', // 分享图标
				success: function () {
					// 设置成功
				},
				fail: function (res) {
					// alert(JSON.stringify(res));
				},
			});
			// eslint-disable-next-line no-undef
			wx.onMenuShareTimeline({
				title: '家庭健康养生班', // 分享标题
				type: 'link',
				link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://images.jkqingman.com/MP/wx_share.jpg', // 分享图标
				success: function () {
					// 设置成功
				},
				fail: function (res) {
					// alert(JSON.stringify(res));
				},
			});
			// eslint-disable-next-line no-undef
			wx.updateTimelineShareData({
				title: '家庭健康养生班', // 分享标题
				link: location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
				imgUrl: 'https://images.jkqingman.com/MP/wx_share.jpg', // 分享图标
				success: function () {
					// 设置成功
				},
				fail: function (res) {
					// alert(JSON.stringify(res));
				},
			});
		});
	});
};

const timer = setInterval(() => {
	// eslint-disable-next-line no-undef
	if (isWeChat() && wx && store.state.token) {
		clearInterval(timer);
		wxSetting && wxSetting();
	}
}, 20);
